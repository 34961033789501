<template>
  <div class="px-5 pt-10 text-center">
    <div class="bg-white rounded">
        <router-link 
            to="/"
        >
            <img
            src="/assets/media/bipart/bipart-logo.png"
            style="max-height: 120px; max-width: 120px;"
            alt=
            />
        </router-link>
        </div>
        <div class="mt-5">
        <router-link 
            to="/about"
            class="text-white rounded mb-2 btn btn-outline-white"
            style="padding: 7px 10px; display: block;"
        >
            <span
            class="font-weight-bolder font-size-base text-uppercase font-weight-bolder"
            >Elenco organizzazioni
            </span>
        </router-link>
        <router-link 
            to="/how-it-works"
            class="text-white rounded mb-2 btn btn-outline-white"
            style="padding: 7px 10px; display: block;"
        >
            <span
            class="font-weight-bolder font-size-base text-uppercase font-weight-bolder"
            >Elenco processi
            </span>
        </router-link>
        <router-link 
            to="/how-it-works"
            class="text-white rounded mb-2 btn btn-outline-white"
            style="padding: 7px 10px; display: block;"
        >
            <span
            class="font-weight-bolder font-size-base text-uppercase font-weight-bolder"
            >Elenco idee
            </span>
        </router-link>
        <router-link 
            to="/how-it-works"
            class="text-white rounded mb-2 btn btn-outline-white"
            style="padding: 7px 10px; display: block;"
        >
            <span
            class="font-weight-bolder font-size-base text-uppercase font-weight-bolder"
            >Elenco esperti
            </span>
        </router-link>
    </div>
  </div>
</template>

<script>


export default {
  name: "CommunityMenu"
}
</script>

<style scoped></style>
