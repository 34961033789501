<template>
  <div class="row h-100 justify-content-between">
    <div class="d-none d-lg-block col-lg-2 col-xl-2 text-white align-items-stretch bg-primary h-100">
      <div class="pl-4">
        <CommunityMenu></CommunityMenu>
      </div>
    </div>
    <div class="col-lg-10 col-xl-10">
      <div class="row mx-6 mt-10">
         <h2 class="card-label h1 font-weight-bolder">{{$t("COMMUNITYSPACE.CS")}}</h2>
      </div>
      <div class="row mx-6 align-items-center">
        
        <form method="get" class="quick-search-form w-100">
          <div class="input-group">
            <div class="input-group-append">
              <span
                class="input-group-text h3 text-primary font-weight-bold bg-transparent border-0"
              >
                {{$t("COMMUNITYSPACE.IDEE")}}
              </span>
            </div>
            <input
              type="text"
              class="form-control bg-transparent py-10 h4 border-0"
              placeholder="Search..."
            />
            <div class="input-group-append">
                <a href="" class="mb-2 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2">
                    <span class="symbol symbol-rounded">
                      <span class="svg-icon svg-icon-white svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <path d="M5,10.5 C5,6 8,3 12.5,3 C17,3 20,6.75 20,10.5 C20,12.8325623 17.8236613,16.03566 13.470984,20.1092932 C12.9154018,20.6292577 12.0585054,20.6508331 11.4774555,20.1594925 C7.15915182,16.5078313 5,13.2880005 5,10.5 Z M12.5,12 C13.8807119,12 15,10.8807119 15,9.5 C15,8.11928813 13.8807119,7 12.5,7 C11.1192881,7 10,8.11928813 10,9.5 C10,10.8807119 11.1192881,12 12.5,12 Z" fill="#000000" fill-rule="nonzero"/>
                        </g>
                    </svg></span>
                  </span>
                </a>
                <b-popover target="pop-map" triggers="hover focus">
                  <template v-slot:title>{{$t("COMMUNITYSPACE.VISUALIZZAMAPPA")}}</template>
                </b-popover>
            </div>
            <div class="input-group-append ml-2">
               <a href="" class="mb-2 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2">
                    <span class="symbol symbol-rounded">
                      <span class="svg-icon svg-icon-white svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"/>
                          <path d="M10,4 L21,4 C21.5522847,4 22,4.44771525 22,5 L22,7 C22,7.55228475 21.5522847,8 21,8 L10,8 C9.44771525,8 9,7.55228475 9,7 L9,5 C9,4.44771525 9.44771525,4 10,4 Z M10,10 L21,10 C21.5522847,10 22,10.4477153 22,11 L22,13 C22,13.5522847 21.5522847,14 21,14 L10,14 C9.44771525,14 9,13.5522847 9,13 L9,11 C9,10.4477153 9.44771525,10 10,10 Z M10,16 L21,16 C21.5522847,16 22,16.4477153 22,17 L22,19 C22,19.5522847 21.5522847,20 21,20 L10,20 C9.44771525,20 9,19.5522847 9,19 L9,17 C9,16.4477153 9.44771525,16 10,16 Z" fill="#000000"/>
                          <rect fill="#000000" opacity="0.3" x="2" y="4" width="5" height="16" rx="1"/>
                      </g>
                  </svg></span>
                  </span>
                </a>
                <b-popover target="pop-list" triggers="hover focus">
                  <template v-slot:title>{{$t("COMMUNITYSPACE.VISUALIZZAELENCO")}}</template>
                </b-popover>
            </div>
            
          </div>
        </form>
      </div>
       <!--::Module Filter 
      <div class="row mx-6">
        <div class="col-xl-3">
          <v-select label="filtra per città" ></v-select>
        </div>
        <div class="col-xl-3">
          <v-select label="filtra per organization" ></v-select>
        </div>
        <div class="col-xl-3">
          <v-select label="filtra per challenge" ></v-select>
        </div>
        <div class="col-xl-3">
          <v-select label="filtra per scenario" ></v-select>
        </div>
      </div>-->
      <div class="row mx-3">
        <div class="col-sm-12 col-md-6 col-lg-4 mb-5">
          <div class="card mb-3 shadow-sm card-stretch h-100">
            <div class="card-header ribbon ribbon-top ribbon-ver py-3">
              <div class="ribbon-target bg-warning" style="top: -2px; right: 20px;">
              <i class="fa fa-star text-white"></i>
              </div>
              <div class="ribbon-target" style="top: -2px; right: 65px;">
                <span class=" text-uppercase font-weight-bolder">{{$t("COMMUNITYSPACE.BOZZA")}}</span>
              </div>
              <div class="d-flex align-items-center">
              <a href="#" class="mr-3 btn btn-icon btn-lg px-2">
                  <span class="symbol symbol-circle">
                    <img
                      alt="Pic"
                      src="/assets/media/empaville/giorgia-gallo.svg"
                    />
                  </span>
                </a>
                <span class="text-muted font-weight-bold">
                 {{$t("COMMUNITYSPACE.NOMEAUTORE")}}
                </span>
              </div>
            </div>
            <div class="card-body py-6">
              <div class="row">
                <div class="col-sm-6 col-md-8 col-lg-8 align-items-center">
                  <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2">
                    <h1 class="font-weight-bold font-size-h4 mb-0">
                      {{$t("COMMUNITYSPACE.TITOLOIDEA")}}</h1>
                    <p class="mt-3" >
                      {{$t("COMMUNITYSPACE.DESCRIZIONEIDEA")}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4  align-items-end">
                  <div class="row justify-content-end mt-10">
                    <div class="d-flex flex-column">
                    </div>
                    <div class="d-flex flex-column">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 mb-5">
          <div class="card mb-3 shadow-sm card-stretch h-100">
            <div class="card-header ribbon ribbon-top ribbon-ver py-3">
              <div class="ribbon-target bg-warning" style="top: -2px; right: 20px;">
              <i class="fa fa-star text-white"></i>
              </div>
              <div class="ribbon-target" style="top: -2px; right: 65px;">
                <span class=" text-uppercase font-weight-bolder"> {{$t("COMMUNITYSPACE.BOZZA")}}</span>
              </div>
              <div class="d-flex align-items-center">
              <a href="#" class="mr-3 btn btn-icon btn-lg px-2">
                  <span class="symbol symbol-circle">
                    <img
                      alt="Pic"
                      src="/assets/media/empaville/giorgia-gallo.svg"
                    />
                  </span>
                </a>
                <span class="text-muted font-weight-bold">
                   {{$t("COMMUNITYSPACE.NOMEAUTORE")}}
                </span>
              </div>
            </div>
            <div class="card-body py-6">
              <div class="row">
                <div class="col-sm-6 col-md-8 col-lg-8 align-items-center">
                  <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2">
                    <h1 class="font-weight-bold font-size-h4 mb-0">
                      {{$t("COMMUNITYSPACE.TITOLOIDEA")}}</h1>
                    <p class="mt-3" >
                      {{$t("COMMUNITYSPACE.DESCRIZIONEIDEA")}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4  align-items-end">
                  <div class="row justify-content-end mt-10">
                    <div class="d-flex flex-column">
                    </div>
                    <div class="d-flex flex-column">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 mb-5">
          <div class="card mb-3 shadow-sm card-stretch h-100">
            <div class="card-header ribbon ribbon-top ribbon-ver py-3">
              <div class="ribbon-target bg-warning" style="top: -2px; right: 20px;">
              <i class="fa fa-star text-white"></i>
              </div>
              <div class="ribbon-target" style="top: -2px; right: 65px;">
                <span class=" text-uppercase font-weight-bolder"> {{$t("COMMUNITYSPACE.BOZZA")}}</span>
              </div>
              <div class="d-flex align-items-center">
              <a href="#" class="mr-3 btn btn-icon btn-lg px-2">
                  <span class="symbol symbol-circle">
                    <img
                      alt="Pic"
                      src="/assets/media/empaville/giorgia-gallo.svg"
                    />
                  </span>
                </a>
                <span class="text-muted font-weight-bold">
                  {{$t("COMMUNITYSPACE.NOMEAUTORE")}}
                </span>
              </div>
            </div>
            <div class="card-body py-6">
              <div class="row">
                <div class="col-sm-6 col-md-8 col-lg-8 align-items-center">
                  <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2">
                    <h1 class="font-weight-bold font-size-h4 mb-0">
                      {{$t("COMMUNITYSPACE.TITOLOIDEA")}}</h1>
                    <p class="mt-3" >
                      {{$t("COMMUNITYSPACE.DESCRIZIONEIDEA")}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4  align-items-end">
                  <div class="row justify-content-end mt-10">
                    <div class="d-flex flex-column">
                    </div>
                    <div class="d-flex flex-column">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 mb-5">
          <div class="card mb-3 shadow-sm card-stretch h-100">
            <div class="card-header ribbon ribbon-top ribbon-ver py-3">
              <div class="ribbon-target bg-warning" style="top: -2px; right: 20px;">
              <i class="fa fa-star text-white"></i>
              </div>
              <div class="ribbon-target" style="top: -2px; right: 65px;">
                <span class=" text-uppercase font-weight-bolder"> {{$t("COMMUNITYSPACE.BOZZA")}}</span>
              </div>
              <div class="d-flex align-items-center">
              <a href="#" class="mr-3 btn btn-icon btn-lg px-2">
                  <span class="symbol symbol-circle">
                    <img
                      alt="Pic"
                      src="/assets/media/empaville/giorgia-gallo.svg"
                    />
                  </span>
                </a>
                <span class="text-muted font-weight-bold">
                  {{$t("COMMUNITYSPACE.NOMEAUTORE")}}
                </span>
              </div>
            </div>
            <div class="card-body py-6">
              <div class="row">
                <div class="col-sm-6 col-md-8 col-lg-8 align-items-center">
                  <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2">
                    <h1 class="font-weight-bold font-size-h4 mb-0">
                      {{$t("COMMUNITYSPACE.TITOLOIDEA")}}</h1>
                    <p class="mt-3" >
                      {{$t("COMMUNITYSPACE.DESCRIZIONEIDEA")}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4  align-items-end">
                  <div class="row justify-content-end mt-10">
                    <div class="d-flex flex-column">
                    </div>
                    <div class="d-flex flex-column">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 mb-5">
          <div class="card mb-3 shadow-sm card-stretch h-100">
            <div class="card-header ribbon ribbon-top ribbon-ver py-3">
              <div class="ribbon-target bg-warning" style="top: -2px; right: 20px;">
              <i class="fa fa-star text-white"></i>
              </div>
              <div class="ribbon-target" style="top: -2px; right: 65px;">
                <span class=" text-uppercase font-weight-bolder"> {{$t("COMMUNITYSPACE.BOZZA")}}</span>
              </div>
              <div class="d-flex align-items-center">
              <a href="#" class="mr-3 btn btn-icon btn-lg px-2">
                  <span class="symbol symbol-circle">
                    <img
                      alt="Pic"
                      src="/assets/media/empaville/giorgia-gallo.svg"
                    />
                  </span>
                </a>
                <span class="text-muted font-weight-bold">
                  {{$t("COMMUNITYSPACE.NOMEAUTORE")}}
                </span>
              </div>
            </div>
            <div class="card-body py-6">
              <div class="row">
                <div class="col-sm-6 col-md-8 col-lg-8 align-items-center">
                  <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2">
                    <h1 class="font-weight-bold font-size-h4 mb-0">
                      {{$t("COMMUNITYSPACE.TITOLOIDEA")}}</h1>
                    <p class="mt-3" >
                      {{$t("COMMUNITYSPACE.DESCRIZIONEIDEA")}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4  align-items-end">
                  <div class="row justify-content-end mt-10">
                    <div class="d-flex flex-column">
                    </div>
                    <div class="d-flex flex-column">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 mb-5">
          <div class="card mb-3 shadow-sm card-stretch h-100">
            <div class="card-header ribbon ribbon-top ribbon-ver py-3">
              <div class="ribbon-target bg-warning" style="top: -2px; right: 20px;">
              <i class="fa fa-star text-white"></i>
              </div>
              <div class="ribbon-target" style="top: -2px; right: 65px;">
                <span class=" text-uppercase font-weight-bolder"> {{$t("COMMUNITYSPACE.BOZZA")}}</span>
              </div>
              <div class="d-flex align-items-center">
              <a href="#" class="mr-3 btn btn-icon btn-lg px-2">
                  <span class="symbol symbol-circle">
                    <img
                      alt="Pic"
                      src="/assets/media/empaville/giorgia-gallo.svg"
                    />
                  </span>
                </a>
                <span class="text-muted font-weight-bold">
                   {{$t("COMMUNITYSPACE.NOMEAUTORE")}}
                </span>
              </div>
            </div>
            <div class="card-body py-6">
              <div class="row">
                <div class="col-sm-6 col-md-8 col-lg-8 align-items-center">
                  <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2">
                    <h1 class="font-weight-bold font-size-h4 mb-0">
                      {{$t("COMMUNITYSPACE.TITOLOIDEA")}}</h1>
                    <p class="mt-3" >
                      {{$t("COMMUNITYSPACE.DESCRIZIONEIDEA")}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4  align-items-end">
                  <div class="row justify-content-end mt-10">
                    <div class="d-flex flex-column">
                    </div>
                    <div class="d-flex flex-column">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>

import CommunityMenu from "@/view/layout/common/CommunityMenu.vue";

export default {
  name: "CommunitySpace",
  components: {
    CommunityMenu
  }
}

</script>

<style></style>
